<template>
    <el-button class="btn-blue" @click="send">发送</el-button>
</template>

<script>
export default {
    name: 'SendTemplateBtn',
    components: {},
    props: {
        com: {
            type: Object,
            default: () => null
        },
        request: {
            type: Function,
            default: () => () => {}
        },
        paramsType: {
            type: String,
            default: "string"
        }
    },
    data() {
        return {
            
        }
    },
    computed: {},
    methods: {
        async send() {
            if(!this.com || !this.com.$refs.elTable) return;
            if(this.com.$refs.elTable.selection.length <= 0) {
                this.$message.warning("请选择要导出的数据");
                return;
            }
            let ids = this.com.$refs.elTable.selection.map(item => item.id)
            if(this.paramsType == "string") {
                ids = ids.join("#");
            }
            this.$common.sendData(this.request, { ids });
        },
    }
}
</script>

<style scoped lang="scss">
.send-template-btn {
    box-sizing: border-box;
    height: 100%;
}
</style>
